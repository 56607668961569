export const events = [
  {
    title: 'Boston Cube Party 2024',
    dates: 'June 22-23, 2024',
    sortDate: '2024-06-23',
    location: 'Cambridge, MA, USA',
    size: '~96',
    infoDescription: 'bostoncubeparty.com',
    infoUrl: 'https://bostoncubeparty.com/',
    discordUrl: 'https://discord.gg/WUfUgqm9m9',
  },
  {
    title: 'California Cube Champs 2024',
    dates: 'June 1-2, 2024',
    sortDate: '2024-06-02',
    location: 'Santa Rosa, CA, USA',
    size: '~95',
    infoDescription: 'Eventbrite',
    infoUrl:
      'https://www.eventbrite.com/e/second-annual-california-cube-championship-tickets-864236553797?aff=oddtdtcreator',
    discordUrl: 'https://discord.com/invite/9fK9PGD3p6',
    soldOut: true,
  },
  {
    title: 'The Bar-B-Cube',
    dates: 'June 15, 2024',
    sortDate: '2024-06-15',
    location: 'St Francis, WI, USA',
    size: '~64',
    infoDescription: 'topdeck.gg',
    infoUrl: 'https://topdeck.gg/event/6OIwBoRehaC7GP0ltitX',
  },
  {
    title: 'Cube at MagicCon Amsterdam',
    dates: 'June 28-30, 2024',
    sortDate: '2024-06-30',
    location: 'Amsterdam, NL',
    infoDescription: '@CubeCornermtg on Twitter',
    infoUrl: 'https://twitter.com/cubecornermtg/',
    discordUrl: 'https://discord.gg/4kk8cEgJdm',
  },
  {
    title: 'CubeDungeonCon',
    dates: 'July 12-14, 2024',
    sortDate: '2024-07-14',
    location: 'Madison, WI, USA',
    size: '~32',
    infoDescription: '@islandponder on Twitter',
    infoUrl: 'https://twitter.com/IslandPonder/status/1771181190617165862',
  },
  {
    title: 'Steel City Cube',
    dates: 'July 20-21, 2024',
    sortDate: '2024-07-21',
    location: 'Pittsburgh, PA, USA',
    size: '~72',
    infoDescription: '@SteelCityCube on Twitter',
    infoUrl: 'https://twitter.com/SteelCityCube',
    discordUrl: 'http://discord.gg/qKBYaHcZEs',
  },
  {
    title: 'CubeCon 2024',
    dates: 'October 17-20, 2024',
    sortDate: '2024-10-20',
    location: 'Madison, WI, USA',
    size: '~550',
    infoDescription: 'cubecon.org',
    infoUrl: 'https://cubecon.org/',
    discordUrl: 'https://discord.gg/gMuVpU6q4Q',
  },
  {
    title: 'UberCube Event',
    dates: 'April 27-28, 2024',
    sortDate: '2024-04-28',
    location: 'Chapel Hill, NC, USA',
    size: '~64',
    infoDescription: 'The Gathering Place',
    infoUrl:
      'https://the-gathering-place.mybigcommerce.com/events/ubercube-event-sign-up/',
  },
  {
    title: 'CubeCon 2022',
    dates: 'October 20-23, 2022',
    sortDate: '2022-10-23',
    location: 'Madison, WI, USA',
    size: '~200',
    infoDescription: 'cubecon.org',
    infoUrl: 'https://cubecon.org/',
    discordUrl: 'https://discord.gg/gMuVpU6q4Q',
  },
  {
    title: 'CubeCon 2023',
    dates: 'October 19-22, 2023',
    sortDate: '2023-10-22',
    location: 'Madison, WI, USA',
    size: '~400',
    infoDescription: 'cubecon.org',
    infoUrl: 'https://cubecon.org/',
    discordUrl: 'https://discord.gg/gMuVpU6q4Q',
  },
  {
    title: 'Washington Cube Champs',
    dates: 'March 9-10, 2024',
    sortDate: '2024-03-10',
    location: 'Bothell, WA, USA',
    size: '~64',
    infoDescription: '@wacubechamps on Twitter',
    infoUrl: 'https://twitter.com/wacubechamps',
    discordUrl: 'https://discord.gg/xrdmdUhhqW',
  },
  {
    title: 'The Ice Cube',
    dates: 'January 20, 2024',
    sortDate: '2024-01-20',
    location: 'St Francis, WI, USA',
    size: '~64',
    infoDescription: 'topdeck.gg',
    infoUrl: 'https://topdeck.gg/event/1RpkfHchDzL1LQydPn6d',
  },
  {
    title: 'California Cube Champs 2023',
    dates: 'June 10-11, 2023',
    sortDate: '2023-06-11',
    location: 'Santa Rosa, CA, USA',
    size: '~64',
    discordUrl: 'https://discord.com/invite/9fK9PGD3p6',
    soldOut: true,
  },
  {
    title: 'Cube For a Cause SS2024',
    dates: 'August 24-25, 2024',
    sortDate: '2024-08-24',
    location: 'Brooklyn, NY, USA',
    size: '~64',
    infoDescription: 'Upkeep New York',
    infoUrl: 'https://www.upkeepnewyork.com/c4ac-ss24-ticket-form',
    discordUrl: 'https://t.co/vDgG2mxAOB',
  },
  {
    title: 'Capitol Cube Championship',
    dates: 'August 17, 2024',
    sortDate: '2024-08-17',
    location: 'Washington DC, USA',
    size: '~64',
    infoDescription: 'Eventbrite',
    infoUrl: 'https://bit.ly/capitolcube2024',
    discordUrl: 'https://discord.com/invite/6QKTQrPVCQ ',
  },
  {
    title: 'CubeOpen Hamburg',
    dates: 'September 7-8, 2024',
    sortDate: '2024-09-07',
    location: 'Hamburg, Germany',
    size: '~32',
    infoDescription: 'mtg-cube.de',
    infoUrl: 'https://mtg-cube.de/',
    discordUrl: 'https://discord.gg/8m5x9QM4bP',
  },
  {
    title: 'Cubing at Commander Sealed',
    dates: 'September 13-15, 2024',
    sortDate: '2024-09-13',
    location: 'Rochester, NY, USA',
    size: '~24',
    infoDescription: 'commandersealed.com/',
    infoUrl: 'https://commandersealed.com/',
    discordUrl: 'https://discord.gg/dHyjM24g4D',
  },
  {
    title: 'Tabletop Solidarity Committee',
    dates: 'October 26, 2024',
    sortDate: '2024-10-26',
    location: 'New York, NY, USA',
    size: '~32',
    infoDescription: 'Upkeep New York',
    infoUrl: 'https://www.upkeepnewyork.com/ttsc-x-uny-ticket-form-1',
    discordUrl: 'https://t.co/vDgG2mxAOB',
  },
  {
    title: 'Boston Cube Party 2025',
    dates: 'April 12-13, 2025',
    sortDate: '2025-04-12',
    location: 'Cambridge, MA, USA',
    size: '~96',
    infoDescription: 'bostoncubeparty.com',
    infoUrl: 'https://bostoncubeparty.com/',
    discordUrl: 'https://discord.gg/WUfUgqm9m9',
  },
  {
    title: 'The Salt Box',
    dates: 'March 8-9, 2025',
    sortDate: '2025-03-08',
    location: 'Baltimore, MD, USA',
    size: '~72',
    infoDescription: 'thesaltbox.fun',
    infoUrl: 'http://thesaltbox.fun/',
    discordUrl: 'https://discord.gg/zXmsH4cpkt',
  },
  {
    title: 'London Cube Clash',
    dates: 'November 17, 2024',
    sortDate: '2024-11-17',
    location: 'London, England',
    size: '~32',
    infoDescription: 'cube.memorici.de',
    infoUrl: 'https://cube.memorici.de/',
    soldOut: true,
  },
  {
    title: 'Cube For a Cause FW2025',
    dates: 'February 22-23, 2025',
    sortDate: '2025-02-22',
    location: 'Brooklyn, NY, USA',
    size: '~64',
    infoDescription: 'More info to come.',
    discordUrl: 'https://t.co/vDgG2mxAOB',
  },
  {
    title: 'Vertex: Philadelphia',
    dates: 'January 25-26, 2025',
    sortDate: '2025-01-25',
    location: 'Philadelphia, PA, USA',
    size: '~128',
    infoDescription: 'Stripe',
    infoUrl: 'https://buy.stripe.com/fZe4j91wB5wT7pSfZ0',
    discordUrl: 'https://discord.gg/e58mAExXH4',
  },
]
